<template>
	<div>
		<v-row class="mx-2">
			<v-col cols="12">
				<v-card flat style="border-radius: 12px" elevation="6">
				<v-card-title class="elevation-10">
					<v-row>
					<v-col class="d-flex align-center justify-start" cols="8">
						<h5 class="d-flex align-left">{{ $t("staff") }}</h5>
					</v-col>
					<v-col cols="4">
						<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						:label="$t('search-staff')"
						single-line
						hide-details
						filled
						dense
						background-color="#E8E8E8"
						rounded
						class="table-search-button"
						/>
					</v-col>
					</v-row>
				</v-card-title>
				<v-data-table
					v-if="users"
					:loading="loading"
					:headers="headers"
					:no-data-text="$t('no-data-available')"
					:loading-text="$t('loading')"
					:search="search"
					:items="users"
					:items-per-page="-1"
					sort-by="id"
					group-by="roleId"
					item-key="id"
					fixed-header
					class="elevation-15"
					dense
					style="cursor: pointer"
					:height="testHeight"
					:single-expand="true"
					:expanded.sync="expanded"
					:footer-props="{
						itemsPerPageText: this.$t('row'),
						itemsPerPageAllText: this.$t('all'),
						}"
				>
				<template v-slot:[`footer.prepend`]>
					<v-btn
						v-if="roleName === 'ORGANIZATION_ADMIN' || roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'"
						class="table-create-button"
						label="Test"
						@click="dialog = true"
					>
						<img class="pt-1" height="20px" width="20px" :src="medical_team_white" style="margin-right:7%; margin-bottom: 5%;"/>
						<span style="text-transform: none;">{{ $t("addNewHospitalStaffTooltip") }}</span>
					</v-btn>
				</template>
				<template v-slot:[`group.header`]="{
							group,
							headers,
							toggle,
							isOpen,
							items,
						}"
					>
					<td @click="toggle" style="background-color:white;" :colspan="headers.length" class="elevation-3">
						<v-row class="align-center" style="padding:2px;">
							<v-col cols="1" class="text-left">
								<span style="font-weight: bold">
									{{ roleTranslate(group) }}</span>
							</v-col>
							<v-col cols="2">
							<v-chip pill x-small color="primary" class="text--black">
								{{ items.length }}
							</v-chip>
							</v-col>
							<v-col cols="9" class="text-right">
								<span>
									<v-btn  small icon :ref="group" :data-open="isOpen">
										<v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
										<v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
									</v-btn>
								</span>
							</v-col>
						</v-row>
					</td>
					<v-spacer></v-spacer>
				</template>
				<template v-slot:item="{ item }">
					<tr @click="expand(item)">
						<td class="table-cols">
							<img v-if="item.roleId === 4" :src="nurse_icon(item.status)" color="primary" height="24px">
							<img v-if="item.roleId === 7" :src="nurse_icon(item.status)" color="primary" height="24px">
							<img :src="doctor_icon(item.status)" v-if="item.roleId === 3" color="primary" height="24px">
						</td>
						<td class="table-cols">
							{{ item.fullName }}
						</td>
						<td v-if="roleName === 'ORGANIZATION_ADMIN'" class="table-cols">
							{{ item.hospitalName }}
						</td>
						<td class="table-cols">
							{{ item.email }}
						</td>
						<td class="table-cols">
							{{ item.phone }}
						</td>
						<td v-if="roleName === 'ORGANIZATION_ADMIN' || roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'" :colspan="3" class="my-0">
							<div label @click.stop="sendActivationEmail(item)" pill small  color="primary" class="float-right" style="display:flex; align-items:center;">
								<div v-if="item.status !== 1">
									<v-tooltip top>
									<template v-slot:activator="{on}">
										<v-icon class="px-2" color="primary" size="27" v-on="on">mdi-email</v-icon>
									</template>
									<span>{{$t('send-activation-email')}}</span>
									</v-tooltip>
								</div>
								<div v-else>
									<v-tooltip top>
									<template v-slot:activator="{on}">
										<v-icon  class="px-2" color="primary" size="27" v-on="on">mdi mdi-lock-reset</v-icon>
									</template>
									<span>{{$t('send-reset-email')}}</span>
									</v-tooltip>
								</div>
								<div>
									<v-switch
										hide-details
										:disabled="item.status === -1"
										v-model="item.status"
										flat
										class="mt-n1 px-1"
										@click.stop="setUserStatus(item)"></v-switch>
								</div>
							</div>
						</td>
					</tr>
				</template>
				<template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<DetailsCard v-if="expanded" :type="'mstaff'" :item="item"></DetailsCard>
					</td>
				</template>
				</v-data-table>
			</v-card>
			<AddStaffDialog v-if="dialog" :dialog="dialog" @false="closeDialog()" @success="success"/>
		</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { AsYouType } from 'libphonenumber-js';
import AddStaffDialog from '@/views/dialogs/MedicalStaffDialog.vue';
import DetailsCard from '@/views/Client/components/DetailsCard.vue';
import { medicalTeamWhite, doctorBlack, doctorSecondary, nurseBlack, nurseSecondary } from '@/assets';
export default {
	components: {
		AddStaffDialog,
		DetailsCard,
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			relatedUsers: (state) => state.hospitals.users,
			detailedHospital: (state) => state.hospitals.detailedHospital,
			medicalStaff: (state) => state.hospitals.medicalStaff,
			newObject: (state) => state.authentication.userData,
			relatedOrganizationMedicalStaff: (state) => state.medicalInstitutions.relatedOrganizationMedicalStaff,
		}),
		...mapGetters({
			hospitalId: 'authentication/getHospitalId',
			pageHeight: 'pageHeight',
			roleName: 'authentication/getRole',
			delay: 'authentication/getDelay',
		}),
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return window.innerHeight - 320;
			case 'sm': return window.innerHeight - 300;
			case 'md': return window.innerHeight - 300;
			case 'lg': return window.innerHeight - 280;
			case 'xl': return window.innerHeight - 280;
			default: return 0;
			}
		},
		headers () {
			if (this.roleName === 'ORGANIZATION_ADMIN') {
				const headers = [
					{ text: this.$t('header-status'), value: 'statusDescription', class: this.headerClass },
					{ text: this.$t('header-full-name'), value: 'fullName', class: this.headerClass },
					{ text: this.$t('header-hospital-name'), value: 'hospitalName', class: this.headerClass },
					{ text: this.$t('header-email'), value: 'email', class: this.headerClass },
					{ text: this.$t('header-phone'), value: 'phone', class: this.headerClass },
					{ text: '', value: 'actions', class: this.headerClass },
					{ value: 'userStatus', class: this.headerClass },
				];
				if (this.roleName !== 'ORGANIZATION_ADMIN' && this.roleName !== 'HOSPITAL_ADMIN' && this.roleName !== 'DOCTOR' && this.roleName !== 'NURSE') {
					headers.pop();
				}
				return headers;
			} else {
				const headers = [
					{ text: this.$t('header-status'), value: 'statusDescription', class: this.headerClass },
					{ text: this.$t('header-full-name'), value: 'fullName', class: this.headerClass },
					{ text: this.$t('header-email'), value: 'email', class: this.headerClass },
					{ text: this.$t('header-phone'), value: 'phone', class: this.headerClass },
					{ text: '', value: 'actions', class: this.headerClass },
					{ value: 'userStatus', class: this.headerClass },
				];
				if (this.roleName !== 'ORGANIZATION_ADMIN' && this.roleName !== 'HOSPITAL_ADMIN' && this.roleName !== 'DOCTOR' && this.roleName !== 'NURSE') {
					headers.pop();
				}
				return headers;
			}
		},
		users: {
			get () {
				if (this.roleName === 'ORGANIZATION_ADMIN') {
					return this.relatedOrganizationMedicalStaff?.filter((ru) => {
						ru.statusDescription = ru.status === 1 ? 'Active' : 'Inactive';
						ru.fullName = ru.firstName.concat(' ', ru.lastName);
						ru.phone = new AsYouType().input(ru.phone);
						ru.hospitalName = ru.hospitalAssignedData !== null ? ru.hospitalsAssignedData[0].hospitalName : '';
						switch (ru.roleId) {
						case 3:
							ru.role = 'Doctor';
							break;
						case 4:
							ru.role = 'Nurse';
							break;
						case 7:
							ru.role = 'Caregiver';
							break;
						default:
							break;
						}
						return ru.roleId === 3 || ru.roleId === 4 || ru.roleId === 7;
					});
				} else {
					return this.medicalStaff?.filter((ru) => {
						ru.statusDescription = ru.status === 1 ? 'Active' : 'Inactive';
						ru.fullName = ru.firstName.concat(' ', ru.lastName);
						ru.phone = new AsYouType().input(ru.phone);
						switch (ru.roleId) {
						case 3:
							ru.role = 'Doctor';
							break;
						case 4:
							ru.role = 'Nurse';
							break;
						case 7:
							ru.role = 'Caregiver';
							break;
						default:
							break;
						}
						return ru.roleId === 3 || ru.roleId === 4 || ru.roleId === 7;
					});
				}
			},
			set (value) {
				// Setter function to assign a new value to "users"
				// this.medicalStaff = value;
			},
		},
	},
	data () {
		return {
			expanded: [],
			loading: false,
			dialog: false,
			headerClass: 'black_inner--text !important;',
			search: null,
			doctor_black: doctorBlack,
			doctor_secondary: doctorSecondary,
			nurse_black: nurseBlack,
			nurse_secondary: nurseSecondary,
			medical_team_white: medicalTeamWhite,
		};
	},
	async mounted () {
		await this.getStaff();
	},
	methods: {
		async setUserStatus (item) {
			const requestData = { userId: item.id, statusValueToSet: !item.status ? 0 : 1 };

			try {
				const res = await this.$store.dispatch('users/setUserStatus', requestData);
				if (res.resFlag) {
					this.users = this.users.map(user => {
						if (user.id === res.data.id) {
							// Change the status of the desired user
							return { ...user, status: res.data.status };
							// eslint-disable-next-line no-prototype-builtins
						} else if (user.hasOwnProperty('status')) {
							// Ensure the 'status' property exists before returning the user object
							return user;
						}
					});
					this.$store.commit('alerts/add', {
						id: 'addedAdmin',
						type: 'success',
						color: 'main_green',
						// message: res.msg,
						message: this.$t('success'),
					});
					this.refreshData();
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				} else {
					this.$store.commit('alerts/add', {
						id: 'addedAdmin',
						type: 'error',
						color: 'main_red',
						// message: res.msg,
						message: this.$t('failed'),
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				}
			} catch (error) {
				this.$store.commit('alerts/add', {
					id: 'addedAdmin',
					type: 'error',
					color: 'main_red',
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		roleTranslate (group) {
			return group === 3 ? this.$t('doctors') : group === 4 ? this.$t('nurses') : this.$t('caregivers');
		},
		closeAll () {
			Object.keys(this.$refs).forEach(k => {
				if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
					this.$refs[k].$el.click();
				}
			});
		},
		async expand (item) {
			if (item === this.expanded[0]) this.expanded = [];
			else this.expanded = [item];
		},
		async getStaff () {
			this.loading = true;
			this.closeAll();

			if (this.roleName === 'ORGANIZATION_ADMIN') {
				const organizationId = this.userData.organizationAssignedData.id; // we take this from store
				await this.$store
					.dispatch('medicalInstitutions/getRelatedOrganizationMedicalStaff', organizationId)
					.then(() => {
						this.loading = false;
					});
			} else {
				const hospitalId = this.userData.hospitalAssignedData.id; // we take this from store
				await this.$store
					.dispatch('hospitals/getRelatedMedicalStaff', hospitalId)
					.then(() => {
						this.loading = false;
					});
			}
			this.closeAll();
		},
		async refreshData () {
			this.loading = true;
			this.getStaff();
		},
		closeDialog () {
			this.dialog = false;
			this.getStaff();
		},
		doctor_icon (item) {
			switch (item) {
			case 1:
				return this.doctor_black;
			default:
				return this.doctor_secondary;
			}
		},
		nurse_icon (item) {
			switch (item) {
			case 1:
				return this.nurse_black;
			default:
				return this.nurse_secondary;
			}
		},
		success (res) {
			this.dialog = false;
			this.getStaff();
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'error',
					color: 'main_red',
					// message: res.msg,
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		async sendActivationEmail (item) {
			const sendValidationEmail = { email: item.email };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						this.$store.commit('alerts/add', {
							id: 'addedAdmin',
							type: 'success',
							color: 'main_green',
							// message: res.msg,
							message: this.$t('success'),
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					} else {
						this.$store.commit('alerts/add', {
							id: 'errorAddingAdmin',
							type: 'error',
							color: 'main_red',
							// message: res.msg,
							message: this.$t('failed'),
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					}
				});
		},
	},
};
</script>
