<template>
  <v-dialog
    v-model="dialog"
    width="720"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    class="dialog-style"
  >
    <v-form v-model="valid">
		<v-card flat style="overflow: hidden">
			<v-card-title class="secondary py-1" style="height: 40px">
				<span class="dialog-title">{{$t('medical')}} {{$t('staff')}}</span>
				<v-spacer />
				<v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
			</v-card-title>
			<v-row class="pl-9 pb-4 mt-2">
				<span style="font-size: 18px">{{$t('personal')}} {{$t('information')}}</span>
			</v-row>
			<v-row dense class="pb-1 px-6">
				<v-col>
					<v-text-field
					v-model="firstName"
					:label="this.$t('name')"
					prepend-inner-icon="mdi-account mr-4"
					dense
					background-color="#E8E8E8"
					rounded
					outlined
					style="border-radius: 12px !important; height: 40px"
					:rules="[(rule) => !!rule || '']"
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
					v-model="lastName"
					:label="this.$t('surname')"
					prepend-inner-icon="mdi-account mr-4"
					dense
					background-color="#E8E8E8"
					rounded
					outlined
					style="border-radius: 12px !important; height: 40px"
					:rules="[(rule) => !!rule || '']"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row dense class="pb-1 px-6">
				<v-col>
					<validation-provider
					ref="email"
					:name="$t('email')"
					v-slot="{ errors }"
					>
					<v-text-field
						v-model="email"
						:label="$t('email')"
						prepend-inner-icon="mdi-email mr-4"
						dense
						:rules="emailRules"
						background-color="#E8E8E8"
						rounded
						outlined
						style="border-radius: 12px !important; height: 40px"
						@change="
						errors.length > 0
							? (emailErrors = true)
							: (emailErrors = false)
						"
						:error-messages="errors"
					></v-text-field>
					</validation-provider>
				</v-col>
				<v-col>
					<v-text-field
					ref="phoneNumber"
					v-model="phoneNumber"
					validate-on-blur
					:label="$t('phone-number')"
					prepend-inner-icon="mdi-cellphone mr-4"
					dense
					background-color="#E8E8E8"
					rounded
					outlined
					style="border-radius: 12px !important; height: 40px"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
				<v-btn-toggle
					class="my-2 justify-start"
					v-model="languageStaff"
					row
					mandatory
					active-class="primary white--text"
					rounded>
					<v-btn
						value='ENG'
						style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
					>{{$t('english')}}</v-btn>
					<v-btn
						value='ESP'
						style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
					>{{$t('spanish')}}</v-btn>
				</v-btn-toggle>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
				<v-btn-toggle
					class="my-2 justify-start"
					v-model="scaleAndTempUnit"
					row
					mandatory
					active-class="primary white--text"
					rounded>
					<v-btn
						value='IMPERIAL'
						style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
						@click="setImperialOrMetric('IMPERIAL')"
					>{{$t('imperial')}}</v-btn>
					<v-btn
						value='METRIC'
						style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
						@click="setImperialOrMetric('METRIC')"
					>{{$t('metric')}}</v-btn>
				</v-btn-toggle>
				</v-col>
				<v-col cols="6">
				<v-btn-toggle
					class="my-2 justify-start"
					v-model="glucoseAndCholesterolUnit"
					row
					mandatory
					active-class="primary white--text"
					rounded>
					<v-btn
						value='mg/dl'
						style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
						@click="setGlucoseAndCholesterolUnit('mg/dl')"
					>{{$t('glucose-mg-dL')}}</v-btn>
					<v-btn
						value='mmol/l'
						style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
						@click="setGlucoseAndCholesterolUnit('mmol/l')"
					>{{$t('glucose-mmol-L')}}</v-btn>
				</v-btn-toggle>
				</v-col>
			</v-row>
			<v-row dense cols="12" class="pb-1 px-6">
				<v-col>
					<v-select
					v-model="role"
					:label="$t('title')"
					:items="roles"
					:item-text="this.$i18n.locale === 'en' ? 'roleNameForDisplay' : 'roleNameForDisplayESP'"
					item-value="id"
					prepend-inner-icon="mdi-badge-account mr-4"
					dense
					background-color="#E8E8E8"
					rounded
					outlined
					style="border-radius: 12px !important; height: 40px"
					:rules="[(rule) => !!rule || '']"
					></v-select>
				</v-col>
				<!-- we have hide this part we gonna populate automaticaly from hospital/office data -->
				<v-col v-if="false">
				<v-autocomplete
					v-model="timezone"
					:label="$t('timezone')"
					:items=timeZones
					prepend-inner-icon="mdi-earth mr-4"
					dense
					item-text="timeZoneId"
					item-value="value"
					background-color="#E8E8E8"
					rounded
					outlined
					style="border-radius: 12px !important; height: 40px"
				></v-autocomplete>
				</v-col>
			</v-row>
			<v-row dense cols="12" class="pb-1 px-6">
				<v-col>
					<v-select
						v-model="hospitalId"
						:label="$t('hospital')"
						:items="hospitals"
						:item-text="'hospitalName'"
						item-value="id"
						prepend-inner-icon="mdi-badge-account mr-4"
						dense
						background-color="#E8E8E8"
						rounded
						outlined
						style="border-radius: 12px !important; height: 40px"
						:rules="[(rule) => !!rule || '']"
					></v-select>
				</v-col>
			</v-row>
			<v-row v-if="role === 3" dense cols="12" class="pb-1 px-6">
				<v-col>
					<v-data-table
							dense
							:headers="headersVerifiers"
							:no-data-text="$t('no-data-available')"
							:loading-text="$t('loading')"
							:items="verifiers"
							hide-default-header
							hide-default-footer
							:search="searchSpecialization"
							class="elevation-1"
							:footer-props="{
								itemsPerPageText: this.$t('row'),
								itemsPerPageAllText: this.$t('all'),
							}"
						>
						<template v-slot:top>
						<v-row class="ma-0 pa-0">
							<v-col class="ma-0 pa-0">
							<span class="d-flex align-start ma-3 font-weight-bold">{{ $t("specializations") }}</span>
							</v-col>
							<v-col class="ma-0 pa-0">
							<v-text-field
								v-model="searchSpecialization"
								append-icon="mdi-magnify"
								:label="$t('search-specialization')"
								single-line
								hide-details
								filled
								outlined
								dense
								background-color="#E8E8E8"
								rounded
								class="table-search-button"
							/>
							</v-col>
						</v-row>
						</template>
						<template v-slot:item="{ item }">
						<tr>
							<td class="d-flex justify-start">{{language(item)}}</td>
							<td><v-checkbox
								style="float: right"
								class="pa-0 ma-0"
								hide-details
								v-model="selected"
								color="primary"
								:value="item.lowValue"
							></v-checkbox></td>
						</tr>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
			<v-card-actions>
				<AlertDialog
					class="mt-2 ml-4"
					v-if="showErrors"
					:title="title"
					:type="alertType"
				></AlertDialog>
				<v-spacer></v-spacer>
				<div class="my-3 mx-2 text-right">
					<v-btn class="table-cancel-button" @click="$emit('false')">
					<span color="black--text">{{ $t("cancel") }}</span>
					</v-btn>
					<v-btn
						class="table-create-button mx-2"
						:loading="loading"
						:disabled="loading || submit"
						@click="loader = 'loading'"
					>
						<span>{{ $t("save") }}</span>
					</v-btn>
					<!-- <v-progress-circular indeterminate color="primary" v-if="loading" /> -->
				</div>
			</v-card-actions>
		</v-card>
    </v-form>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState, mapGetters } from 'vuex';
import AlertDialog from '@/views/Client/components/AlertDialog.vue';
import BirthdatePicker from '@/views/Client/components/BirthdatePicker';
import accountOutline from '@/assets/icons_v2/account-outline.png';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';

export default {
	components: {
		AlertDialog,
		// BirthdatePicker,
	},
	props: ['dialog'],
	data: () => ({
		loader: null,
		headersVerifiers: [
			{
				text: '',
				value: 'longDescription',
			},
			{
				text: '',
			},
		],
		showErrors: false,
		title: 'Something went wrong',
		alertType: false,
		loading: false,
		selected: [],
		profile: accountOutline,
		snackStatus: false,
		snackColor: '',
		status: '',
		timezone: [],
		firstName: '',
		lastName: '',
		phone: '',
		searchSpecialization: null,
		username: '',
		email: '',
		emailErrors: true,
		userTimeZone: '',
		gender: '',
		birthdate: '2002-01-01',
		role: -1,
		roles: [],
		hospitalId: -1,
		hospitalSelected: null,
		hospitals: [],
		valid: false,
		phoneNumber: '+1',
		phoneNumberError: true,
		licenceNumber: null,
		specializations: null,
		addressToValidate: null,
		formattedAddress: null,
		validationErrors: [],
		languageStaff: 'ENG',
		scaleAndTempUnit: 'IMPERIAL',
		glucoseAndCholesterolUnit: 'mg/dl',
		emailRules: [
			v => !!v || 'E-mail is required',
			v => /.+@.+/.test(v) || 'E-mail must be valid',
		],
	}),
	computed: {
		...mapState({
			hospitalsFromRepo: (state) => state.hospitals.hospitals,
			rolesFromRepo: (state) => state.roles.roles,
			timeZones: (state) => state.verifiers.timeZones,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
			loggedUser: (state) => state.authentication.userData,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
		}),
		verifiers () {
			return this.verifiersFromRepo;
		},
		submit () {
			if (this.phoneNumberError) {
				return true;
			}
			if (this.emailErrors) {
				return true;
			}
			return false;
		},
		hospitalData () {
			if (this.roleName === 'ORGANIZATION_ADMIN') {
				if (this.hospitalId !== -1) {
					return this.hospitals.find(h => h.id === this.hospitalId);
				} else {
					return this.loggedUser.organizationAssignedData;
				}
			} else {
				return this.loggedUser.hospitalAssignedData;
			}
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.createStaff()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		role (val) {
			if (val === 4) {
				this.selected = [];
			}
		},
		phoneNumber (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneNumberError = true;
				} else {
					this.phoneNumberError = false;
				}
				this.phoneNumber = new AsYouType().input(val);
			}
		},
		hospitalId (val) {
			this.hospitalSelected = this.hospitals.find(h => h.id === val);
		},
	},
	async mounted () {
		await this.getTimeZoneOfHospital();
		await this.getHospitals();
		await this.getRoles();
		await this.getSpecializations();
		// await this.getTimeZones();
	},
	methods: {
		language (item) {
			return this.$i18n.locale === 'en' ? item.longDescription : item.longDescriptionEsp;
		},
		setImperialOrMetric (unit) {
			this.scaleAndTempUnit = unit;
			this.getPreferences();
		},
		setGlucoseAndCholesterolUnit (unit) {
			this.glucoseAndCholesterolUnit = unit;
			this.getPreferences();
		},
		getPreferences () {
			const preferences = {
				Units: this.scaleAndTempUnit,
				Concentration: this.glucoseAndCholesterolUnit,
			};
			return preferences;
		},
		async getSpecializations () {
			const header = {
				domainValue: 'SPECIALIZATION_TYPE',
			};
			await this.$store
				.dispatch('verifiers/getVerifiersForDomain', header)
				.then(() => {
					this.specializationTypes = this.verifiersFromRepo;
				});
		},
		async editNurseMetaData (metadatabody) {
			await this.$store.dispatch(
				'nursemetadata/editNurseMetaData',
				metadatabody,
			);
		},
		async updateDoctorMetaData (metadatabody) {
			await this.$store.dispatch(
				'doctormetadata/editDoctorMetaData',
				metadatabody,
			);
		},
		async assignDoctorToSpetialization (specializationbody) {
			await this.$store.dispatch(
				'doctormetadata/assignDoctorToSpetialization',
				specializationbody,
			);
		},
		async timeZone (lat, lng) {
			const object = {
				lat: lat,
				lng: lng,
			};
			const timeZone = await this.$store.dispatch(
				'googleAPI/getTimeZone',
				object,
			);
			return timeZone;
		},
		async getTimeZoneOfHospital () {
			const tz = await this.timeZone(
				this.hospitalData.addressLatitude,
				this.hospitalData.addressLongitude,
			);
			if (tz) {
				this.timezone = tz.zoneName;
			}
		},
		// async locationDetails () {
		// 	if (this.addressToValidate) {
		// 		const geoLocation = await this.$store.dispatch(
		// 			'googleAPI/getGeoLocation',
		// 			this.addressToValidate,
		// 		);
		// 		const addressComponents = geoLocation.address_components;
		// 		this.address_info.lat = geoLocation.geometry.location.lat;
		// 		this.address_info.lng = geoLocation.geometry.location.lng;
		// 		this.formattedAddress = geoLocation.formatted_address;
		// 		this.address_info.address = this.formattedAddress;
		// 		addressComponents.forEach((ac) => {
		// 			if (ac.types.includes('administrative_area_level_1')) {
		// 				this.address_info.state = ac.long_name;
		// 			}
		// 			if (ac.types.includes('locality')) {
		// 				this.address_info.city = ac.long_name;
		// 			}
		// 			if (ac.types.includes('country')) {
		// 				this.address_info.country = ac.long_name;
		// 			}
		// 			if (ac.types.includes('postal_code')) {
		// 				this.address_info.postal_code = ac.long_name;
		// 			}
		// 		});
		// 	}
		// },
		// async locationDetails () {
		// 	if (this.addressToValidate) {
		// 		this.waiting = true;
		// 		const geoLocation = await this.$store.dispatch(
		// 			'googleAPI/getGeoLocation',
		// 			this.addressToValidate,
		// 		);
		// 		this.waiting = false;
		// 		if (!geoLocation) {
		// 			this.validationErrors.push('address');
		// 			this.address_info = {
		// 				address: '',
		// 				postal_code: '',
		// 				city: '',
		// 				state: '',
		// 				country: '',
		// 				lat: 0,
		// 				lng: 0,
		// 			};
		// 			return;
		// 		}
		// 		const addressComponents = geoLocation.address;
		// 		this.address_info.lat = geoLocation.lat;
		// 		this.address_info.lng = geoLocation.lon;
		// 		this.formattedAddress = geoLocation.display_name;
		// 		this.address_info.address = this.formattedAddress;
		// 		this.address_info.city = addressComponents.city;
		// 		this.address_info.state = addressComponents.state;
		// 		this.address_info.country = addressComponents.country;
		// 		this.address_info.postal_code = addressComponents.postcode;
		// 		if (this.address_info.lat !== null) {
		// 			const tz = await this.timeZone(
		// 				this.address_info.lat,
		// 				this.address_info.lng,
		// 			);
		// 			if (tz) {
		// 				this.timezone = tz.zoneName;
		// 			}
		// 		}
		// 	}
		// },
		checkValid () {
			if (this.phoneNumberError) {
				this.validationErrors.push(this.$t('phone'));
			}
			// if (this.address_info.address !== "") {
			//   this.validationErrors.push("address");
			// }
			if (this.role === -1) {
				this.validationErrors.push(this.$t('title'));
			}
			if (this.validationErrors.length > 0) {
				return false;
			} else return true;
		},
		async createStaff () {
			const valid = this.checkValid();
			if (!valid) {
				this.title = this.$t('providedData') + this.validationErrors.toString();
				this.alertType = 'error';
				this.showErrors = true;
				return;
			}
			this.loading = true;
			this.snackStatus = false;
			const timezone = await this.timeZone(
				this.hospitalData.addressLatitude,
				this.hospitalData.addressLongitude,
			);
			const body = {
				firstName: this.firstName,
				lastName: this.lastName,
				phone: this.phoneNumber,
				email: this.email,
				address: this.hospitalData.address,
				zip: this.hospitalData.zip,
				city: this.hospitalData.city,
				state: this.hospitalData.state,
				country: this.hospitalData.country,
				addressLatitude: this.hospitalData.addressLatitude,
				addressLongitude: this.hospitalData.addressLongitude,
				username: this.email,
				password: '123456789',
				roleId: this.role,
				language: this.languageStaff,
				hospitalId: this.hospitalData.id,
				userTimezone: this.timezone,
				userSettingsJson: JSON.stringify(this.getPreferences()),
			};
			if (this.role === 4 || this.role === 7) {
				await this.$store.dispatch('users/createUser', body).then(async (res) => {
					if (res.statusCode >= 200 && res.statusCode < 300) {
						this.loading = false;
						this.$emit('success', res);
						const metadatabody = {
							userId: res.data.id,
							metadataInfo: '',
							licenceId: this.licenceNumber,
							hospitalId: this.hospitalData.id,
						};
						if (this.role === 4) {
							await this.editNurseMetaData(metadatabody);
						}
					}
				});
			} else if (this.role === 3) {
				const metadatabody = {
					userId: null,
					metadataInfo: '',
					licenceId: this.licenceNumber,
				};
				await this.$store
					.dispatch('users/createUser', body)
					.then(async (res) => {
						if (res.statusCode >= 200 && res.statusCode < 300) {
							metadatabody.userId = res.data.id;
							const specializationbody = {
								doctorUserId: res.data.id,
								spetializationTypeId: null,
								hospitalId: this.hospitalData.id,
							};
							await this.updateDoctorMetaData(metadatabody);
							this.loading = false;
							this.$emit('success', res);
							if (this.selected.length > 0) {
								this.selected.forEach(async (s) => {
									specializationbody.spetializationTypeId = s;
									await this.assignDoctorToSpetialization(specializationbody);
								});
							}
						}
					});
			}
		},
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
				if (this.hospitalsFromRepo !== null) {
					this.hospitals = this.hospitalsFromRepo;
				} else this.hospitals = [];
			});
		},
		async getTimeZones () {
			await this.$store.dispatch('verifiers/getTimeZones');
		},
		async getRoles () {
			await this.$store.dispatch('roles/getRoles').then(() => {
				this.roles = this.rolesFromRepo.filter(
					(role) => role.roleName === 'DOCTOR' || role.roleName === 'NURSE' || role.roleName === 'CARE_GIVER',
				);
			});
		},
	},
};
</script>

<style>
::-webkit-scrollbar {
  width: 0px !important;
}
</style>
